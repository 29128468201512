import { identify, page, track } from "integrations/segment/client/tracking";
import { useEffect } from "react";

export enum SegmentEventPages {
  userFeedback = "User-feedback",
  slack = "Post to Slack",
  chatTranscript = "Chat Transcripts Info",
}

export enum UserFeedbackEventActions {
  clickedSave = "Clicked save",
  clickedExportCsv = "Clicked Export CSV",
  clickedExportPdf = "Clicked Export PDF",
  editedAnalysisItem = "Edited feedback analysis item",
  deletedAnalysisItem = "Deleted feedback analysis item",
  sortedAnalysisItem = "Sorted feedback analysis item",
}

export enum SlackEventActions {
  connected = "Connected Slack",
  sentTestMsg = "Sent test message",
  changedChannel = "Changed Slack channel",
  disconnected = "Disabled Slack connection",
}

type SegmentEventAction = UserFeedbackEventActions | SlackEventActions;

type TaggedEvent = {
  page: SegmentEventPages;
  action: SegmentEventAction;
  meta?: string;
  payload?: Record<string, number | string | boolean>;
};

export const trackTaggedEvent = (taggedEvent: TaggedEvent): void => {
  const meta = taggedEvent.meta ? ` (${taggedEvent.meta})` : "";
  const eventTag = `${taggedEvent.page}: ${taggedEvent.action}${meta}`;

  track(eventTag, taggedEvent.payload);
};

export const trackFeedbackItemEvent = (
  page: SegmentEventPages,
  action: SegmentEventAction,
  meta?: string
) => {
  trackTaggedEvent({
    page,
    action,
    meta,
  });
};

export const useAnalytics = () => {
  return {
    trackUser: identify,
    trackPage: page,
    trackEvent: track,
    trackTaggedEvent,
  };
};

export const useTrackPageInitialLoad = () => {
  const { trackPage } = useAnalytics();

  useEffect(() => {
    trackPage(window.location.pathname);
  }, [trackPage]);
};
