import { KlassyClient } from "./KlassyClient";
import { klassyServiceUrl, klassyAuthToken } from "./config";

export * from "./KlassyClient";

let _klassyClient: KlassyClient;

export function klassyClient(): KlassyClient {
  return (
    _klassyClient ||
    (_klassyClient = new KlassyClient({
      baseURL: klassyServiceUrl(),
      token: klassyAuthToken(),
    }))
  );
}
