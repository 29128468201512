import { PopoverContentProps } from "@reactour/tour";
import classNames from "classnames";

import styles from "../tour.module.css";

type NavigationDotsProps = Pick<PopoverContentProps, "steps" | "currentStep">;
export function NavigationDots(props: NavigationDotsProps) {
  return (
    <div className={styles.dotContainer}>
      {props.steps.map((_step, index) => {
        return (
          <div
            key={`tour-step-incidators-${index}`}
            className={classNames(styles.dot, { [styles.active!]: props.currentStep === index })}
          ></div>
        );
      })}
    </div>
  );
}
