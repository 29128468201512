import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { getEnvironment } from "app/utils/common";

export const initializeSentry = () => {
  Sentry.init({
    dsn: "https://7d34941566cc45ac85184acf1734670c@o4504618140172288.ingest.sentry.io/4504618159505408",
    integrations: [new BrowserTracing()],
    environment: getEnvironment(),

    tracesSampleRate: 0.25,
  });
};
