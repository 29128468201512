import { PropsWithChildren } from "react";

import { Button } from "app/core/components/Button";
import { GoogleLogoSVG } from "app/core/components/GoogleLogoSVG";

export const GoogleAuthButton = ({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) => {
  return (
    <a href="/api/auth/google" className={className}>
      <Button className="w-full">
        <GoogleLogoSVG className="mr-4" />
        {children}
      </Button>
    </a>
  );
};
