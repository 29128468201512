import classNames from "classnames";
import cx from "classnames";
import { ComponentPropsWithoutRef, forwardRef, PropsWithoutRef } from "react";
import { useField, UseFieldConfig } from "react-final-form";

export interface LabeledTextFieldProps extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number";
  dataTestTarget?: string;
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  labelProps?: ComponentPropsWithoutRef<"label">;
  fieldProps?: UseFieldConfig<string>;
}

export const LabeledTextField = forwardRef<HTMLInputElement, LabeledTextFieldProps>(
  ({ name, label, dataTestTarget, outerProps, fieldProps, labelProps, ...props }, ref) => {
    const {
      input,
      meta: { touched, error, submitError, submitting },
    } = useField(name, {
      // Converting `""` to `null` ensures empty values will be set to null in the DB
      parse: (v) => (v === "" ? null : props.type === "number" ? Number(v) : v),
      ...fieldProps,
    });

    let normalizedError: string = Array.isArray(error) ? error.join(", ") : error || submitError;
    if (normalizedError && normalizedError.includes("received null")) {
      // Normalize weird null value messages from Zod schema
      normalizedError = "Please provide a value";
    }

    return (
      <div {...outerProps}>
        <label
          className={classNames("flex flex-col items-start text-gray-600", props.className)}
          {...labelProps}
        >
          {label}
          <input
            {...input}
            data-test-target={dataTestTarget}
            disabled={submitting}
            {...props}
            className={cx("rounded mt-2 py-1 px-2 bg-gray-100", props.className)}
            ref={ref}
          />
        </label>

        {touched && normalizedError && (
          <div role="alert" className="text-red-500 py-2">
            {normalizedError}
          </div>
        )}
      </div>
    );
  }
);

export default LabeledTextField;
