import { validateZodSchema } from "blitz";
import { PropsWithoutRef, ReactNode } from "react";
import { Form as FinalForm, FormProps as FinalFormProps } from "react-final-form";
import { z } from "zod";

import { Button } from "./Button";

export { FORM_ERROR } from "final-form";

export interface FormProps<S extends z.ZodType<any, any>>
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["form"]>, "onSubmit"> {
  /** All your form fields */
  children?: ReactNode;
  footer?: ReactNode;
  /** Text to display in the submit button */
  submitText?: string;
  schema?: S;
  onSubmit: FinalFormProps<z.infer<S>>["onSubmit"];
  initialValues?: FinalFormProps<z.infer<S>>["initialValues"];
  buttonClassName?: string;
  dataTestTargetSubmit?: string;
}

export function Form<S extends z.ZodType<any, any>>({
  children,
  footer,
  submitText,
  schema,
  initialValues,
  onSubmit,
  buttonClassName,
  dataTestTargetSubmit,
  ...props
}: FormProps<S>) {
  return (
    <FinalForm
      initialValues={initialValues}
      validate={validateZodSchema(schema)}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit} className="form" {...props}>
          {/* Form fields supplied as children are rendered here */}
          {children}

          {submitError && (
            <div className="text-red-500 mt-4" role="alert">
              {submitError}
            </div>
          )}

          {submitText && (
            <div className="w-full flex flex-row justify-center items-center mt-8">
              <Button dataTestTarget={dataTestTargetSubmit} disabled={submitting} htmlType="submit">
                {submitText}
              </Button>
            </div>
          )}

          {footer}
        </form>
      )}
    />
  );
}

export default Form;
