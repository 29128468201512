import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import { AnalysisItemStatus } from "integrations/klassy/feedback-api-v2-schema";
import { times } from "lodash";

import { isApifySource, isFeedbackSourceZapierAllTypes } from "../klever/feedback/source-utils";
import { FeedbackFilterDayjsOption, FeedbackSourceType } from "../klever/feedback/types";

import { isAnalysisCompletedStage } from "./analysis";

dayjs.extend(utc);
dayjs.extend(quarterOfYear);

export type DateStartAndEndChoice = Date | "week" | "month";
export type FeedbackDaySelection = FeedbackFilterDayjsOption;

export type DateRange = {
  /**
   * Inclusive.
   */
  dateStart: Date;
  /**
   * Exclusive.
   */
  dateEnd: Date;
};

/**
 * A wrapper around dayjs.utc(...) so we don't have to extend dayjs in every module
 */
export const getUTCDayJS = (date?: string | number | dayjs.Dayjs | Date | null) => dayjs.utc(date);

/**
 * Returns the beginning of today in UTC time
 */
export const getTodayUTC = () => getUTCDayJS().startOf("day");

/**
 * Returns a dateStart and dateEnd where dateStart is the start of the date passed (utc) and dateEnd is the start of the next day.
 * For weeks it returns last week Monday to Monday.
 */
export const getDateStartAndEnd = (
  date: string | number | Date | dayjs.Dayjs | null | undefined
): DateRange => {
  if (date === "week") {
    return {
      dateStart: dayjs
        .utc()
        .subtract(1, "week")
        .startOf("week")
        .add(1, "day")
        .startOf("day")
        .toDate(),
      dateEnd: dayjs.utc().subtract(1, "week").endOf("week").add(2, "day").startOf("day").toDate(),
    };
  } else if (date === "month") {
    return {
      dateStart: dayjs.utc().subtract(1, "month").startOf("month").toDate(),
      dateEnd: dayjs.utc().startOf("month").toDate(),
    };
  } else if (date === "lastQuarter") {
    return {
      dateStart: dayjs.utc().subtract(1, "quarter").startOf("quarter").toDate(),
      dateEnd: dayjs.utc().startOf("quarter").toDate(),
    };
  }

  return {
    dateStart: dayjs.utc(date).startOf("day").toDate(),
    dateEnd: dayjs.utc(date).add(1, "day").startOf("day").toDate(),
  };
};

export const TODAY = dayjs().startOf("day");
export const YESTERDAY = TODAY.subtract(1, "day");

const PREVIOUS_WEEKS_CUTOFF = 21;
const PREVIOUS_MONTHS_CUTOFF = 1;

export const getSecondAndEarlierWeeks = (dateRanges: Array<DateRange>, refDate = getTodayUTC()) => {
  return dateRanges.filter((range: DateRange) => {
    const daysDiff = dayjs.utc(range.dateEnd).diff(range.dateStart, "days");

    if (daysDiff !== 7) {
      return false;
    }

    const daysDiffFromToday = dayjs.utc(range.dateEnd).diff(refDate, "days");

    return Math.abs(daysDiffFromToday) >= 7;
  });
};

export const getPreviousMonthsIfTheyExist = (
  dateRanges: Array<DateRange>,
  refDate = getTodayUTC()
) => {
  // look for months 2, 3, 4, etc if they exist
  const previousMonths = dateRanges.filter((range: DateRange) => {
    const utcDateStart = dayjs.utc(range.dateStart);
    const utcDateEnd = dayjs.utc(range.dateEnd);

    const monthsDiffFromToday = utcDateEnd.diff(refDate, "months");
    const monthsDiff = utcDateEnd.diff(utcDateStart, "months");

    return monthsDiff >= 1 && Math.abs(monthsDiffFromToday) >= PREVIOUS_MONTHS_CUTOFF;
  });

  return previousMonths.sort((a, b) => b.dateStart.getTime() - a.dateStart.getTime());
};

export const getQuartersFromDateRanges = (dateRanges: Array<DateRange>) => {
  const quarters = dateRanges.filter((range) => isQuarterRangeOption(range));
  const sortedQuarters = quarters.sort((a, b) =>
    dayjs.utc(b.dateStart).diff(dayjs.utc(a.dateStart), "days")
  );
  return sortedQuarters;
};

export const getYearsFromDateRanges = (dateRanges: Array<DateRange>) => {
  const years = dateRanges.filter((range) => isYearRangeOption(range));
  return years.sort((a, b) => dayjs.utc(b.dateStart).diff(dayjs.utc(a.dateStart), "days"));
};

export const getStandardRangeDates = (dateRanges: Array<DateRange>, type?: FeedbackSourceType) => {
  if (type == FeedbackSourceType.group) {
    const previousWeeksToShow = 9;
    const sourceRangeDates: FeedbackDaySelection[] = dateRanges
      .slice(0, previousWeeksToShow)
      .map((range) =>
        getDaySelectionFromRange(
          {
            dateStart: range.dateStart,
            dateEnd: range.dateEnd,
          },
          getTodayUTC(),
          previousWeeksToShow * 7
        )
      );

    return sourceRangeDates;
  } else if (type && isFeedbackSourceZapierAllTypes(type)) {
    const sourceRangeDates: FeedbackDaySelection[] = dateRanges.map((range) =>
      getDaySelectionFromRange(
        {
          dateStart: range.dateStart,
          dateEnd: range.dateEnd,
        },
        getTodayUTC()
      )
    );
    return sourceRangeDates;
  } else if (type && isApifySource(type)) {
    const sourceRangeDates: FeedbackDaySelection[] = [];
    sourceRangeDates.push("week");
    // previous month
    sourceRangeDates.push("month");

    // The rest of the weeks
    sourceRangeDates.push(...getSecondAndEarlierWeeks(dateRanges));

    const previousMonths = getPreviousMonthsIfTheyExist(dateRanges);
    sourceRangeDates.push(...previousMonths);

    return sourceRangeDates;
  } else {
    const yesterday = getTodayUTC().subtract(1, "day");
    const sourceRangeDates: FeedbackDaySelection[] = times(7, (i) => yesterday.subtract(i, "days"));
    // previous week
    sourceRangeDates.push("week");

    // The rest of the weeks
    sourceRangeDates.push(...getSecondAndEarlierWeeks(dateRanges));

    // previous month
    sourceRangeDates.push("month");

    const previousMonths = getPreviousMonthsIfTheyExist(dateRanges);
    sourceRangeDates.push(...previousMonths);

    return sourceRangeDates;
  }
};

export const getDaySelectionFromRange = (
  range: DateRange,
  refDate = getTodayUTC(),
  previousWeekCutoff = PREVIOUS_WEEKS_CUTOFF
): FeedbackDaySelection => {
  const utcDateStart = dayjs.utc(range.dateStart);
  const utcDateEnd = dayjs.utc(range.dateEnd);

  const daysDiffFromToday = utcDateEnd.diff(refDate, "days");
  const daysDiff = utcDateEnd.diff(utcDateStart, "days");
  if (daysDiff === 1) return utcDateStart;

  // only select previous week, 2nd previous week, and 3rd previous week
  if (daysDiff === 7 && Math.abs(daysDiffFromToday) < previousWeekCutoff) {
    if (Math.abs(daysDiffFromToday) < 7) return "week";
    else return range;
  }

  const monthsDiffFromToday = utcDateEnd.diff(refDate, "months");
  const monthsDiff = utcDateEnd.diff(utcDateStart, "months");

  // select all monthly ranges
  if (monthsDiff >= 1) {
    if (Math.abs(monthsDiffFromToday) < PREVIOUS_MONTHS_CUTOFF) return "month";
    else return range;
  }

  // Fallback to daily range
  return utcDateStart;
};

export const initialAppDateRanges = (): Array<DateRange> =>
  [1, 2, 3, 4, 5, 6, 7]
    .map((daysToSubtract) => {
      const dateStart = getTodayUTC().subtract(daysToSubtract, "day");

      return {
        dateStart: dateStart.toDate(),
        dateEnd: dateStart.add(1, "day").toDate(),
      };
    })
    .concat([getDateStartAndEnd("week"), getDateStartAndEnd("month")]);

export const getFormattedQuarterName = (dateRange: DateRange) => {
  const startDate = dayjs.utc(dateRange.dateStart);
  const quarter = Math.floor(startDate.month() / 3) + 1;
  const year = startDate.year();

  return `Q${quarter} of ${year}`;
};

export const hasQuarterlyAnalysis = (validDateRanges: AnalysisItemStatus[]) => {
  return getQuartersFromDateRanges(validDateRanges).length > 0;
};

export const getFormattedYearName = (_dateRange: DateRange) => {
  // Hardcoding this for now.
  return "Full year";
};

export const isQuarterRangeOption = (dateRange: DateRange) => {
  const start = dayjs.utc(dateRange.dateStart);
  const end = dayjs.utc(dateRange.dateEnd);

  // Check if start is the first day of a quarter
  const isStartOfQuarter = start.date() === 1 && [0, 3, 6, 9].includes(start.month());
  //
  const isEndOfQuarter = start.endOf("quarter").add(1, "day");

  // Check if end matches the end of the quarter
  const isEndOneDayAfterQuarter = end.isSame(isEndOfQuarter, "day");

  return isStartOfQuarter && isEndOneDayAfterQuarter;
};

export const isYearRangeOption = (dateRange: DateRange) => {
  const start = dayjs.utc(dateRange.dateStart);
  const end = dayjs.utc(dateRange.dateEnd);

  // Check if start is the first day of a quarter
  const isStartOfQuarter = start.date() === 1 && [0, 3, 6, 9].includes(start.month());
  //
  const isEndOfQuarter = start.add(4, "quarters");

  // Check if end matches the end of the quarter
  const isEndOneDayAfterQuarter = end.isSame(isEndOfQuarter, "day");

  return isStartOfQuarter && isEndOneDayAfterQuarter;
};

export const dateRangeNameExtended = [
  "today",
  "yesterday",
  "lastWeek",
  "lastMonth",
  "last1Quarter",
  "last2Quarter",
  "last3Quarter",
  "last4Quarter",
  "last4Quarters",
  "all",
] as const;

export type DateRangeNameExtended = typeof dateRangeNameExtended[number];

const readableMap = {
  today: "Today",
  yesterday: "Yesterday",
  lastWeek: "Last Week",
  lastMonth: "Last Month",
  last1Quarter: "Last 1st Quarter",
  last2Quarter: "Last 2nd Quarter",
  last3Quarter: "Last 3rd Quarter",
  last4Quarter: "Last 4th Quarter",
  last4Quarters: "Last 4 Quarters",
  all: "All",
};

export function dateRangeExtendedToReadable(input: DateRangeNameExtended) {
  return readableMap[input];
}

export function getDateRanges(
  validDateRanges: AnalysisItemStatus[],
  sourceType: FeedbackSourceType
): FeedbackFilterDayjsOption[] {
  const dateRanges = getStandardRangeDates(validDateRanges, sourceType);
  return dateRanges;
}

export function getFormattedDateForQuery(date: Date): string {
  return date.toISOString().split("T")[0] || "";
}

export const getDateRangeStatusMap = (
  validDateRanges: AnalysisItemStatus[]
): Map<string, AnalysisItemStatus> => {
  return new Map<string, AnalysisItemStatus>(
    validDateRanges
      .filter((range) => range.dateStart != null && range.dateEnd != null)
      .map((range) => [getRangeKey(range), range])
  );
};

const getRangeKey = (range: DateRange) =>
  `${getUTCDayJS(range.dateStart).format("YYYY-MM-DD")}_${getUTCDayJS(range.dateEnd).format(
    "YYYY-MM-DD"
  )}`;

export const getDateRangeStatus = (
  validDateRanges: AnalysisItemStatus[],
  range: DateRange
): AnalysisItemStatus | undefined => {
  const dateRangeStatusMap = getDateRangeStatusMap(validDateRanges);
  return dateRangeStatusMap.get(getRangeKey(range));
};

export const isValidDateRange = (
  range: DateRange,
  dateRangeStatusMap: Map<string, AnalysisItemStatus>,
  sourceType: FeedbackSourceType
) => {
  //This method confirms that the date range exits and analysis is completed

  const status = dateRangeStatusMap.get(getRangeKey(range));

  if (status == null) return false;
  const { analysisStatus } = status;
  if (analysisStatus == null) return false;

  // The "All" source doesn't have count
  if (sourceType === FeedbackSourceType.group) {
    return isAnalysisCompletedStage(analysisStatus);
  }

  // Otherwise, check for at least one item in the range
  return isAnalysisCompletedStage(analysisStatus) && analysisStatus.context.count > 0;
};

export const getSelectedDateRange = (
  query: Record<string, string | string[]>,
  type: FeedbackSourceType,
  createdAt: Date,
  validDateRanges: AnalysisItemStatus[]
): DateRange => {
  const queryDateStartRaw = query.dateStart;
  const queryDateEndRaw = query.dateEnd;
  const monthDateRange = getDateStartAndEnd("month");
  const weekDateRange = getDateStartAndEnd("week");

  const validDateRangeMap = getDateRangeStatusMap(validDateRanges);

  const startDate = queryDateStartRaw
    ? !Array.isArray(queryDateStartRaw)
      ? queryDateStartRaw
      : queryDateStartRaw[0]
    : null;
  const endDate = queryDateEndRaw
    ? !Array.isArray(queryDateEndRaw)
      ? queryDateEndRaw
      : queryDateEndRaw[0]
    : null;

  if (startDate === "week" || startDate === "month") return getDateStartAndEnd(startDate);

  if (startDate && endDate) {
    return { dateStart: getUTCDayJS(startDate).toDate(), dateEnd: getUTCDayJS(endDate).toDate() };
  } else if (startDate) {
    return {
      dateStart: getUTCDayJS(startDate).toDate(),
      dateEnd: getUTCDayJS(startDate).add(1, "day").toDate(),
    };
  } else if (type === FeedbackSourceType.csv || type === FeedbackSourceType.freeFormText) {
    return getDateStartAndEnd(getUTCDayJS(createdAt).toDate());
  } else {
    if (isValidDateRange(monthDateRange, validDateRangeMap, type)) {
      return monthDateRange;
    } else {
      return weekDateRange;
    }
  }
};

export function dateToWeekOf(date: Date) {
  return `Week of ${dayjs(date).format("M/DD/YY")}`;
}
