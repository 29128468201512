export const fontString =
  "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'";

export const THEME_TOKENS = {
  // Fonts
  fontFamily: fontString,
  fontSize: 14,
  fontWeightMedium: 500,
  // Table
  tableCellPadding: 8,
  colorSelectedBg: "#FFFFFF",
  // Colors
  warningBorderColor: "#fffbe6",
  colorInfoBg: "#F8F8F8",
  colorInfoBorder: "#F8F8F8",
  gray: "#758482",
};

export const ANTD_GLOBAL_THEME = {
  token: {
    fontFamily: THEME_TOKENS.fontFamily,
    colorWarningBorder: THEME_TOKENS.warningBorderColor,
    colorInfoBg: THEME_TOKENS.colorInfoBg,
    colorInfoBorder: THEME_TOKENS.colorInfoBorder,
  },
  components: {
    Menu: { fontFamily: THEME_TOKENS.fontFamily },
    Table: {
      cellFontSize: THEME_TOKENS.fontSize,
      cellPaddingBlock: THEME_TOKENS.tableCellPadding,
      cellPaddingInline: THEME_TOKENS.tableCellPadding,
      fontFamily: THEME_TOKENS.fontFamily,
      // Header styles for table
      headerColor: THEME_TOKENS.gray,
      fontWeightStrong: THEME_TOKENS.fontWeightMedium,
      bodySortBg: THEME_TOKENS.colorSelectedBg,
    },
  },
};
