import { Routes } from "@blitzjs/next";
import { useMutation } from "@blitzjs/rpc";
import { AuthenticationError } from "blitz";
import Link from "next/link";
import { useState } from "react";

import { Form, FORM_ERROR } from "app/core/components/Form";
import { LabeledTextField } from "app/core/components/LabeledTextField";
import { useAnalytics } from "app/core/hooks/useAnalytics";

import { BlackBorderPanel } from "app/product-research/components/common/Panel";

import login from "app/auth/mutations/login";
import { Login } from "app/auth/validations";

import { FEATURE_SHOW_GOOGLE_LOGIN } from "../shared/common";

import { GoogleAuthButton } from "./GoogleAuthButton";

type LoginFormProps = {
  initialEmail?: string;
  onSuccess?: (user: { id: number; email: string; name: string }) => void;
};

export const LoginForm = (props: LoginFormProps) => {
  const [loginMutation] = useMutation(login);
  const [showConfirmationResendLink, setShowConfirmationResendLink] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState("");
  const { trackEvent } = useAnalytics();

  return (
    <div className="flex justify-center">
      <BlackBorderPanel
        className="flex-grow-0 p-8 w-full"
        style={{ width: 400, borderColor: "black" }}
      >
        <h1 className="font-medium text-lg pb-4 text-center">Log in to your account</h1>

        {FEATURE_SHOW_GOOGLE_LOGIN ? (
          <>
            <div className="mb-4">
              <GoogleAuthButton>Login with Google</GoogleAuthButton>
            </div>
            <div className="relative mb-2">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500 text-xs">OR</span>
              </div>
            </div>
          </>
        ) : null}

        <Form
          submitText="Log in"
          schema={Login}
          initialValues={{ email: props.initialEmail || "", password: "" }}
          onSubmit={async (values) => {
            trackEvent("Login Start");
            setConfirmationEmail(values.email);
            setShowConfirmationResendLink(false);
            try {
              const user = await loginMutation(values);
              trackEvent("Login Success");
              props.onSuccess?.({
                id: user.id,
                email: user.email,
                name: user.name || "",
              });
            } catch (error: any) {
              trackEvent("Login Error");
              if (error instanceof AuthenticationError) {
                if (error.message.includes("Unconfirmed")) {
                  setShowConfirmationResendLink(true);
                  return {
                    [FORM_ERROR]:
                      "Sorry, the email address for this account has not been confirmed. Use the link above to resend your confirmation email.",
                  };
                }
                return { [FORM_ERROR]: "Sorry, those credentials are invalid" };
              } else {
                return {
                  [FORM_ERROR]:
                    "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
                };
              }
            }
          }}
          footer={
            <div className="flex flex-row justify-between items-center w-full text-center text-sm mt-4">
              <Link legacyBehavior href={Routes.SignupPage({ email: props.initialEmail })}>
                <a className="text-black cursor-pointer">Create an account</a>
              </Link>
              <Link legacyBehavior href={Routes.SetPasswordPage({ email: props.initialEmail })}>
                <a className="text-black cursor-pointer">Forgot your password?</a>
              </Link>
            </div>
          }
        >
          <LabeledTextField
            className="w-full"
            name="email"
            label="Email address"
            placeholder="Email"
          />
          <LabeledTextField
            className="w-full mt-4"
            name="password"
            label="Password"
            placeholder="Password"
            type="password"
          />
          {showConfirmationResendLink && (
            <div className="pt-2 text-center">
              <a
                href={`/api/auth/email-confirmation-resend?email=${encodeURIComponent(
                  confirmationEmail
                )}`}
                className="text-black cursor-pointer"
              >
                Resend confirmation link
              </a>
            </div>
          )}
        </Form>
      </BlackBorderPanel>
    </div>
  );
};

export default LoginForm;
