import { StrictStepType } from "./types";

export const initialSteps: StrictStepType[] = [
  {
    selector: "merge-insights-step",
    content: "Check related summaries to start merging them into one.",
  },
  {
    selector: "delete-insights-step",
    content: "Delete summaries you're not interested in.",
  },
  {
    selector: "edit-insights-step",
    content: "Edit summaries to include additional context.",
  },
  {
    selector: "add-source-step",
    content: "Analyze more sources. Connect 30+ sources here.",
  },
  {
    selector: "date-picker-step",
    content:
      "For most sources, we gather and analyze your feedback daily. Daily, weekly, and monthly summaries can be found here.",
  },
  {
    selector: "ask-ai-step",
    content: "Ask follow up questions to learn more about your user feedback.",
  },
  {
    selector: "user-stories-step",
    content: () => {
      return (
        <p>
          Create tickets with user stories & acceptance criteria.
          <br />
          <br />
          You can create multiple tickets at once and automatically sync to Jira or Linear.
        </p>
      );
    },
  },
  {
    selector: "invite-team-step",
    content: "Invite your team to collaborate on user feedback.",
  },
  {
    selector: "repeat-tour-step",
    content: "If you need help later, take this tour again.",
  },
];
