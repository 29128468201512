import { XIcon } from "@heroicons/react/outline";
import { PopoverContentProps } from "@reactour/tour";

import { Button } from "app/core/components/Button";
import { useAnalytics } from "app/core/hooks/useAnalytics";

import styles from "../tour.module.css";

import { NavigationDots } from "./NavigationDots";

export function TourContent(props: PopoverContentProps) {
  const { trackEvent } = useAnalytics();

  const isFirstStep = props.currentStep === 0;
  const isLastStep = props.currentStep === props.steps.length - 1;
  const content = props.steps[props.currentStep]?.content ?? "";

  const closeAndReset = () => {
    props.setCurrentStep(0);
    props.setIsOpen(false);
  };

  const onNext = () => {
    if (!isLastStep) {
      return props.setCurrentStep((s) => s + 1);
    }

    trackEvent("Product tour completed");
    closeAndReset();
  };

  const onPrev = () => {
    props.setCurrentStep((s) => s - 1);
  };

  const onClose = () => {
    trackEvent("Product tour skipped", { step: props.currentStep });
    closeAndReset();
  };

  return (
    <div className={styles.popover}>
      <div className={styles.header}>
        <div className={styles.title}>
          {typeof content === "function"
            ? // This is incorrectly typed as "(props) => void" by reactour
              (content as (props: PopoverContentProps) => React.ReactNode)(props)
            : content}
        </div>
        <Button size="small" variant="text-muted" onClick={onClose}>
          <XIcon width={16} />
        </Button>
      </div>
      <div className={styles.footer}>
        <NavigationDots steps={props.steps} currentStep={props.currentStep} />

        <div className={styles.buttonGroup}>
          {!isFirstStep && (
            <Button size="small" variant="base" onClick={onPrev}>
              Previous
            </Button>
          )}

          <Button className={styles.next} variant="secondary" size="small" onClick={onNext}>
            {isLastStep ? "Finish" : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
}
