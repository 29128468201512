import classNames from "classnames";
import Link from "next/link";
import React, { ButtonHTMLAttributes, PropsWithChildren } from "react";

import { toEnum } from "../utils/types";

import buttonStyles from "./button.module.css";

const buttonVariants = [
  "warn",
  "text",
  "muted",
  "secondary",
  "accent",
  "integration",
  "base",
  "text-muted",
  "link",
  "black",
] as const;

export type ButtonVariants = typeof buttonVariants[number];

export const ButtonVariant = toEnum(buttonVariants);

export type ButtonProps = React.PropsWithChildren<
  Partial<ButtonHTMLAttributes<HTMLButtonElement>> & {
    variant?: ButtonVariants;
    dataTestTarget?: string;
    style?: React.CSSProperties;
    loadingText?: string;
    icon?: JSX.Element;
    htmlType?: "button" | "submit" | "reset";
    loading?: boolean;
    href?: string;
    target?: "_blank" | "_self" | "_parent" | "_top" | string;
    // Normal shouldn't be passed as a prop, it's the default size
    size?: "small" | "normal";
    square?: boolean;
    inline?: boolean;
  }
>;

export const Button = ({
  children,
  className = "",
  disabled,
  onClick,
  type,
  dataTestTarget,
  variant = ButtonVariant.base,
  icon,
  htmlType,
  style,
  loading,
  loadingText,
  href,
  target,
  size = "normal",
  square,
  inline,
  ...rest
}: ButtonProps) => {
  if (href) {
    return (
      <Link href={href} target={target}>
        <button
          className={classNames(
            buttonStyles.buttonBase,
            className,
            buttonStyles[variant],
            buttonStyles[size],
            { [buttonStyles.square!]: !!square },
            { [buttonStyles.disabled!]: disabled === true },
            { [buttonStyles.inline!]: !!inline }
          )}
          onClick={(e) => {
            if (disabled) return;
            onClick?.(e);
          }}
          style={style}
          type={htmlType}
          {...rest}
        >
          {icon ? (
            <div
              className={classNames(buttonStyles.buttonIcon, {
                [buttonStyles.buttonIconWithContent!]: !!children,
              })}
            >
              {icon}
            </div>
          ) : null}
          {children}
        </button>
      </Link>
    );
  }

  return (
    <button
      className={classNames(
        buttonStyles.buttonBase,
        className,
        buttonStyles[variant],
        buttonStyles[size],
        { [buttonStyles.square!]: !!square },
        { [buttonStyles.disabled!]: disabled === true },
        { [buttonStyles.inline!]: !!inline }
      )}
      onClick={(e) => {
        if (disabled) return;
        onClick?.(e);
      }}
      style={style}
      type={htmlType}
      {...rest}
    >
      {icon ? (
        <div
          className={classNames(buttonStyles.buttonIcon, {
            [buttonStyles.buttonIconWithContent!]: !!children,
          })}
        >
          {icon}
        </div>
      ) : null}
      {children}
    </button>
  );
};

type ButtonGroupProps = PropsWithChildren<{}>;

export function ButtonGroup({ children }: ButtonGroupProps) {
  return <div className={buttonStyles.buttonGroup}>{children}</div>;
}
