import classNames from "classnames";
import React, { ReactNode } from "react";

export const PanelLayout = ({
  children,
  footer,
  title,
}: React.PropsWithChildren<{
  footer?: ReactNode;
  title?: ReactNode;
}>) => {
  return (
    <div className="flex flex-col bg-white border rounded-md h-full">
      <div className="pt-4 pb-4 px-4 flex-grow-0">{title}</div>
      <div className="flex-grow">{children}</div>
      <div className="flex-grow-0">{footer}</div>
    </div>
  );
};

export const PanelTitle = ({ children }: React.PropsWithChildren<{}>) => (
  <h3 className="text-md font-medium truncate">{children}</h3>
);

export const TitledPanel = ({
  children,
  emoji,
  footer,
  title,
}: React.PropsWithChildren<{
  emoji?: ReactNode;
  title: string;
  footer?: ReactNode;
}>) => {
  const renderedTitle = (
    <div className="flex flex-row">
      <div className="flex-grow flex flex-row items-center">
        <>
          {emoji ? (
            <div className="flex-grow-0 flex items-center mr-1 text-2xl" style={{ height: 20 }}>
              {emoji}
            </div>
          ) : null}
          <div className="flex-grow">
            <PanelTitle>{title}</PanelTitle>
          </div>
        </>
      </div>
    </div>
  );

  return (
    <PanelLayout title={renderedTitle} footer={footer}>
      {children}
    </PanelLayout>
  );
};

export const SplitTitledPanel = ({
  children,
  emoji,
  footer,
  title,
  titleRight,
  rightColumnTitle,
}: React.PropsWithChildren<{
  emoji?: ReactNode;
  title: ReactNode | string;
  titleRight?: ReactNode;
  footer?: ReactNode;
  rightColumnTitle?: ReactNode;
}>) => {
  return (
    <PanelLayout
      title={
        <>
          <div className="flex sm:flex-row flex-col">
            <div className="flex-grow flex flex-row items-center">
              <>
                {emoji ? (
                  <div
                    className="flex-grow-0 flex items-center mr-1 text-2xl"
                    style={{ height: 20 }}
                  >
                    {emoji}
                  </div>
                ) : null}
                {typeof title === "string" ? (
                  <div className="flex-grow">
                    <PanelTitle>{title}</PanelTitle>
                  </div>
                ) : (
                  <>{title}</>
                )}
              </>
            </div>
            <div className="flex-grow-0 self-end">{titleRight}</div>
          </div>
        </>
      }
      footer={footer}
    >
      {rightColumnTitle && (
        <div className="flex flex-col items-end px-4 text-sm py-1 bg-gray-100 text-gray-500">
          {rightColumnTitle}
        </div>
      )}
      {children}
    </PanelLayout>
  );
};

export const BorderlessPanel = ({
  children,
  className,
}: React.PropsWithChildren<{
  className?: string;
}>) => {
  return <div className={classNames("bg-white rounded-md shadow", className)}>{children}</div>;
};

export const BlackBorderPanel = ({
  children,
  className,
  style,
}: React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
}>) => {
  return (
    <div
      className={classNames("bg-white border-2 border-black rounded-md", className)}
      style={style}
    >
      {children}
    </div>
  );
};
