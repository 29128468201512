import { TourProps, TourProvider } from "@reactour/tour";
import { PropsWithChildren } from "react";

import { TourContent } from "./components/TourContent";

import { initialSteps } from "./utils/steps";

export function ProductTourProvider({ children }: PropsWithChildren) {
  const steps: TourProps["steps"] = initialSteps.map(({ selector, content }) => ({
    selector: `[data-tour="${selector}"]`,
    content,
    position: ({ x, y }) => (!x || !y ? "center" : "left"),
  }));

  return (
    <TourProvider
      steps={steps}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: 8,
          padding: 16,
          minWidth: 400,
          maxWidth: "50%",
        }),
        maskWrapper: (base) => ({ ...base, opacity: 0.5 }),
        maskArea: (base) => ({
          ...base,
          fill: base.x > 0 ? base.fill : "transparent",
          rx: 8,
        }),
      }}
      ContentComponent={TourContent}
    >
      {children}
    </TourProvider>
  );
}
