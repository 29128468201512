import * as Sentry from "@sentry/react";
import { Breadcrumb } from "@sentry/react";
import { CaptureContext } from "@sentry/types/types";

export * as Sentry from "@sentry/react";

export const identifyUser = ({ id, email }: { id: string; email: string }) => {
  Sentry.setUser({
    id,
    email,
  });
};

export const addBreadcrumb = (crumb: Breadcrumb) => {
  Sentry.addBreadcrumb(crumb);
};

export const captureException = (exception: any, context?: CaptureContext) => {
  Sentry.captureException(exception, context);
};
