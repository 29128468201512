import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from "@blitzjs/next";
import { useQueryErrorResetBoundary } from "@blitzjs/rpc";
import { ConfigProvider } from "antd";
import { AuthenticationError, AuthorizationError } from "blitz";
import { ANTD_GLOBAL_THEME } from "config/theme";
import { page } from "integrations/segment/client/tracking";
import { initializeSentry } from "integrations/sentry/client/init";
import { captureException } from "integrations/sentry/client/tracking";
import Head from "next/head";
import { Router } from "next/router";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import ModalContainer from "react-modal-promise";

import { useTrackPageInitialLoad } from "app/core/hooks/useAnalytics";
import "app/core/styles/index.css";

import LoginForm from "app/auth/components/LoginForm";

import { withBlitz } from "app/blitz-client";
import { ProductTourProvider } from "app/tour/ProductTourProvider";

// Show a progress bar on navigation start
Router.events.on("routeChangeStart", () => nProgress.start());
Router.events.on("routeChangeError", () => nProgress.done());
Router.events.on("routeChangeComplete", (url: string) => {
  // Complete the progress bar
  nProgress.done();
  // Track page changes
  page(url);
});

export default withBlitz(function App({ Component, pageProps }: AppProps) {
  // We track route changes above, but still need to track the initial page loads here
  useTrackPageInitialLoad();
  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    // Initialize sentry error tracking
    initializeSentry();
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta key="og:title" property="og:title" content="Kraftful" />
        <meta key="og:type" property="og:type" content="website" />

        <meta key="og:image" property="og:image" content={"/opengraph/surveys_embed.png"} />

        <meta key="og:url" property="og:url" content="https://klever.kraftful.com" />
      </Head>

      <ErrorBoundary
        FallbackComponent={RootErrorFallback}
        onReset={useQueryErrorResetBoundary().reset}
      >
        <ConfigProvider theme={ANTD_GLOBAL_THEME}>
          <ProductTourProvider>{getLayout(<Component {...pageProps} />)}</ProductTourProvider>
          <Toaster />
        </ConfigProvider>
        <ModalContainer />
      </ErrorBoundary>
    </>
  );
});

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  useEffect(() => {
    captureException(error);
  }, [error]);

  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />;
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    );
  } else {
    return (
      <ErrorComponent
        statusCode={error.statusCode || 400}
        title="Sorry, there was a problem completing that request"
      />
    );
  }
}
